.box,
.sa-box {
  border-radius: 10px;
  background: var(--primary-blue-default);
  margin-bottom: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: var(--neutral-white); }
  .box-show-more,
  .sa-box-show-more {
    padding: 2px 0;
    display: flex;
    justify-content: center; }
    .box-show-more .button,
    .sa-box-show-more .button {
      margin: 0 5px;
      outline-offset: -3px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 10px; }
      .box-show-more .button span,
      .sa-box-show-more .button span {
        font-size: 12px;
        font-family: "Rubik-Medium"; }
        .box-show-more .button span img,
        .sa-box-show-more .button span img {
          height: 10px; }
  .box.alt,
  .sa-box.alt {
    background: var(--neutral-grey-20);
    color: var(--primary-black-dark);
    box-shadow: 0 0 2px 0 #00000047; }
    .box.alt .sa-box-body,
    .sa-box.alt .sa-box-body {
      border-radius: 10px;
      background: var(--neutral-grey-20); }
    .box.alt .header,
    .sa-box.alt .header {
      background: var(--neutral-white); }
    .box.alt .button,
    .sa-box.alt .button {
      outline-color: var(--state-lightblue-default); }
  .box-header,
  .sa-box-header {
    background: var(--box-header);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px 10px 0 0;
    padding: 8px 16px; }
    .box-header h1, .box-header h2, .box-header h3,
    .sa-box-header h1,
    .sa-box-header h2,
    .sa-box-header h3 {
      font-family: 'Rubik-Medium';
      font-weight: 500;
      font-size: 14px; }
  .box-collapse.button .collapse-arrow,
  .sa-box-collapse.button .collapse-arrow {
    background: inherit;
    transition: all 0.3s ease; }
    .box-collapse.button .collapse-arrow.up,
    .sa-box-collapse.button .collapse-arrow.up {
      transform: rotate(-180deg); }
  .box-body,
  .sa-box-body {
    background: var(--box-body);
    max-height: 5000px;
    transition: max-height .5s ease-in; }
    .box-body .sa-box-header,
    .sa-box-body .sa-box-header {
      padding: 0 20px;
      height: 40px; }
    .box-body.hidden,
    .sa-box-body.hidden {
      transition: max-height .0s ease-out;
      max-height: 0;
      height: 0;
      display: none; }

.button {
  background: none;
  border-radius: 3px;
  width: 70px;
  height: 35px;
  border: none;
  outline-color: lightgray;
  cursor: pointer;
  position: relative;
  text-align: center;
  font-size: 12px;
  font-family: 'Rubik-Medium'; }
  .button.disabled {
    opacity: 0.5;
    cursor: default; }
  .button.large {
    width: 100px;
    height: 45px; }
  .button.small {
    width: 50px;
    height: 25px; }
  .button.round {
    border-radius: 23px; }
  .button.full-width {
    width: 100%; }
  .button.alt-font {
    font-family: 'Rubik-Regular';
    font-size: 14px; }
  .button.elastic {
    width: auto;
    height: auto;
    min-height: 35px; }
    .button.elastic.large {
      min-height: 45px;
      min-width: 125px; }
    .button.elastic.small {
      min-height: 25px;
      min-width: 75px; }
  .button-primary {
    background-color: var(--state-teal-default);
    color: var(--neutral-white); }
  .button-primary:hover:not(.disabled) {
    background-color: var(--state-teal-light); }
  .button-primary:active {
    background-color: var(--state-teal-dark); }
  .button-secondary {
    background-color: var(--neutral-grey-40);
    color: var(--primary-black-dark); }
  .button-secondary:hover:not(.disabled) {
    background-color: var(--neutral-grey-20); }
  .button-secondary:active {
    background-color: var(--neutral-grey-60); }
  .button-red {
    background: var(--state-red-default);
    color: var(--neutral-white); }
    .button-red:hover {
      background: var(--state-red-dark); }
  .button-simple {
    border: solid 1px var(--outline-grey-60);
    background-color: var(--neutral-white);
    color: #464646; }
  .button-simple:hover:not(.disabled) {
    background-color: var(--neutral-grey-20); }
  .button-simple:active {
    background-color: var(--neutral-grey-40); }
  .button-dark {
    background-color: var(--neutral-grey-80);
    color: #fff; }
  .button-dark:hover:not(.disabled) {
    background-color: var(--neutral-grey-60); }
  .button-blank {
    border-radius: 0;
    padding: 0px;
    background: none;
    color: inherit;
    width: auto;
    height: auto; }
  .button-warning {
    background-color: var(--state-orange-default);
    color: #fff; }
  .button-warning:hover:not(.disabled) {
    background-color: var(--state-orange-light); }
  .button-warning:active {
    background-color: var(--state-orange-dark); }

.sa-slider-card {
  width: 200px;
  border-radius: 10px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); }
  .sa-slider-card .calculated-text {
    color: #606060; }

.sa-slider-card * {
  font-family: 'Roboto-Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 12px; }

.sa-slider-card > .sa-slider-card-sliders {
  padding: 12px 8px 4px 8px;
  background: var(--sa-range-slider-background-colour, #fff); }

.sa-slider-card > div > .rangeSlider {
  padding: 0 0 16px 0; }

.sa-slider-card > div > p {
  margin: 0 0 8px 0;
  width: 100%;
  height: 16px;
  color: var(--sa-range-slider-text-colour, #121212); }

.sa-slider-card > div > p > .price {
  float: right;
  color: var(--sa-range-slider-text-colour, #121212);
  font-weight: 500; }

.sa-slider-card > button {
  display: block;
  width: 100%;
  cursor: pointer;
  height: 32px; }

.sa-slider-card > *:disabled {
  opacity: .5;
  cursor: unset; }

.sa-slider-card > .btn-1 {
  border: 0;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  background: var(--sa-range-slider-background-colour, #fff);
  color: var(--sa-range-slider-controls-background-colour, #1e6dc7);
  font-weight: 500; }

.sa-slider-card > .btn-2 {
  border: 0;
  border-radius: 0px 0px 10px 10px;
  background: var(--sa-range-slider-controls-background-colour, #1e6dc7);
  color: var(--sa-range-slider-background-colour, #fff);
  font-weight: 500; }

.rangeSlider .slider-label {
  display: flex;
  margin: 0;
  height: 16px;
  justify-content: space-between;
  font-size: 12px; }

.rangeSlider .title {
  color: #606060;
  margin-right: 5px; }

.rangeSlider .value {
  color: #121212;
  display: inline; }
  .rangeSlider .value .range-slider-button {
    cursor: pointer;
    border: none;
    background-color: white;
    padding: 0; }
  .rangeSlider .value input {
    width: fit-content;
    border: none;
    size: 5; }

.rangeSlider input[type='range'] {
  /* Overwrite default style */
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  border-radius: 20px;
  background: #e8e8e8; }

.rangeSlider input[type='range']::-webkit-slider-thumb {
  /* Overwrite default style */
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  background: #ebebeb;
  border: 3px solid var(--sa-range-slider-controls-background-colour, #1e6dc7); }

.accessibility-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute; }

.sa-chat-message-cards {
  display: flex;
  overflow-x: auto;
  margin: auto; }
  .sa-chat-message-cards-item {
    margin-right: 20px;
    border-radius: 10px;
    min-width: 200px;
    border-color: var(--sa-card-border-colour, none);
    border-width: var(--sa-card-border-width, 1px);
    background: var(--sa-card-background-colour, #fff);
    border-style: solid;
    font-family: "Rubik-Medium";
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); }
    .sa-chat-message-cards-item.single {
      margin: auto; }
    .sa-chat-message-cards-item h2 {
      font-size: var(--sa-card-heading-font-size, 16px);
      color: var(--sa-card-heading-text-colour, initial);
      background: var(--sa-card-heading-background-colour, inherit);
      padding: 8px 8px;
      margin: 0;
      font-weight: 500;
      text-align: center;
      border-radius: 10px; }
    .sa-chat-message-cards-item ul {
      list-style: none;
      padding: 0;
      margin: 0; }
    .sa-chat-message-cards-item li {
      display: flex;
      box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px; }
      .sa-chat-message-cards-item li:last-child a {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px; }
    .sa-chat-message-cards-item a, .sa-chat-message-cards-item button {
      font-family: "Rubik-Medium";
      cursor: pointer;
      font-weight: 500;
      border: none;
      background: inherit;
      text-align: center;
      font-size: var(--sa-card-item-font-size, 15px);
      color: var(--sa-card-item-text-colour, black);
      text-decoration: none;
      height: 100%;
      width: 100%; }
      .sa-chat-message-cards-item a p, .sa-chat-message-cards-item button p {
        padding: 4px;
        margin: 16px 8px;
        border-radius: 15px;
        background: var(--sa-card-item-button-inner-background-colour, inherit); }
    .sa-chat-message-cards-item.disabled {
      opacity: .5; }
      .sa-chat-message-cards-item.disabled button {
        cursor: unset; }
    .sa-chat-message-cards-item img {
      width: 100%;
      height: var(--sa-card-image-height, auto);
      margin: 4px; }

.sa-chat-message-bigbuttons {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center; }
  .sa-chat-message-bigbuttons.disabled {
    opacity: 0.5; }
  .sa-chat-message-bigbuttons a, .sa-chat-message-bigbuttons button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 20px;
    cursor: pointer;
    border-radius: 10px;
    padding: 8px;
    min-width: 112px;
    text-decoration: none;
    border-style: solid;
    background: var(--sa-big-button-background-colour, #fff);
    border-width: var(--sa-big-button-border-width, 1px);
    border-color: var(--sa-big-button-border-colour, black);
    font-size: var(--sa-big-button-font-size, 16px);
    color: var(--sa-big-button-text-colour, black);
    text-align: center;
    box-sizing: border-box; }
    .sa-chat-message-bigbuttons a:hover, .sa-chat-message-bigbuttons a:focus, .sa-chat-message-bigbuttons button:hover, .sa-chat-message-bigbuttons button:focus {
      outline: black solid 2px; }

@-moz-document url-prefix() {
  .sa-chat-message-bigbuttons a:hover, .sa-chat-message-bigbuttons a:focus, .sa-chat-message-bigbuttons button:hover, .sa-chat-message-bigbuttons button:focus {
    outline: black solid 2px; } }
  .sa-chat-message-bigbuttons img {
    height: var(--sa-big-button-icon-size, 32px);
    margin-bottom: 16px; }
  .sa-chat-message-bigbuttons h1 {
    font-size: 14px;
    font-family: var(--rubik-regular);
    margin: 0; }

.sa-message-iframe {
  margin: 0 auto;
  border-radius: 10px; }

.sa-chat-messages-container {
  background: #3c5168; }

.sa-chat-message-text {
  width: fit-content;
  display: flex;
  font-family: "Roboto-Regular";
  padding: 10px;
  border-radius: 10px;
  border-bottom-left-radius: 0; }
  .sa-chat-message-text p {
    width: fit-content;
    margin: 0;
    font-size: var(--sa-message-text-font-size, 14px);
    white-space: pre-line; }
  .sa-chat-message-text time {
    align-self: flex-end;
    margin-left: 5px;
    font-size: var(--sa-message-timestamp-text-size, 12px);
    cursor: default;
    -webkit-user-select: none; }
  .sa-chat-message-text-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 60%; }
    .sa-chat-message-text-wrapper .display-name {
      position: relative;
      left: 10px;
      font-size: 12px;
      color: #8A8D91; }
    .sa-chat-message-text-wrapper img {
      content: var(--sa-company-logo-url);
      border-radius: 50%;
      align-self: flex-end;
      height: 24px;
      width: 24px;
      margin-right: 10px;
      margin-bottom: 3px; }
  .sa-chat-message-text__customer {
    background: var(--sa-message-customer-background-colour, #fff);
    color: var(--sa-message-customer-text-colour, #000);
    border-style: solid;
    border-width: var(--sa-message-customer-border-width, 0px);
    border-color: var(--sa-message-customer-border-colour); }
    .sa-chat-message-text__customer time {
      color: var(--sa-message-customer-timestamp-text-colour, var(--outline-grey-60)); }
    .sa-chat-message-text__customer a {
      color: var(--sa-message-customer-link-text-colour, auto); }
  .sa-chat-message-text__agent {
    background: var(--sa-message-agent-background-colour, var(--state-lightblue-default));
    color: var(--sa-message-agent-text-colour, #fff);
    border-style: solid;
    border-width: var(--sa-message-agent-border-width, 0px);
    border-color: var(--sa-message-agent-border-colour); }
    .sa-chat-message-text__agent a {
      color: var(--sa-message-agent-link-text-colour, auto); }
    .sa-chat-message-text__agent time {
      color: var(--sa-message-agent-timestamp-text-colour, inherit); }

.sa-chat-message-meta {
  text-align: center;
  color: var(--sa-message-events-text-colour, var(--outline-grey-40, black)); }

.sa-chat-message-wrapper {
  display: flex;
  width: 100%;
  padding: 8px; }
  .sa-chat-message-wrapper.right > * {
    margin-left: auto; }
  .sa-chat-message-wrapper.right .sa-chat-message-text {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 0px; }
  .sa-chat-message-wrapper .date-picker-message {
    margin: 0 auto; }

.chat-user {
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--outline-grey-60);
  border-radius: 100%;
  max-width: 25px;
  max-height: 25px;
  margin-left: 5px; }
  .chat-user img {
    margin: 0; }

.screen-reader-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute; }

.sa-checkbox-group-error {
  color: var(--state-red-default);
  font-size: 12px; }

.sa-checkbox-container {
  flex-direction: row-reverse;
  justify-content: flex-end;
  cursor: pointer;
  margin-right: 10px;
  width: fit-content;
  display: inline-flex;
  align-items: center; }
  .sa-checkbox-container input[type=checkbox] {
    height: 0;
    width: 0;
    opacity: 0;
    margin: 0; }
  .sa-checkbox-container .checkbox {
    position: relative;
    height: 20px;
    width: 20px;
    background-color: var(--neutral-white);
    border-radius: 100%;
    border: 1px solid var(--checkbox-border);
    margin-right: 10px; }
    .sa-checkbox-container .checkbox.focused {
      outline: auto;
      outline-color: darkgray; }
    .sa-checkbox-container .checkbox:hover {
      background-color: #ccc; }
    .sa-checkbox-container .checkbox.checked:not(.square):after {
      content: "";
      position: absolute;
      background-color: var(--state-teal-default);
      left: 4px;
      top: 4px;
      width: 10px;
      height: 10px;
      border-radius: 100%; }
    .sa-checkbox-container .checkbox-wrapper {
      display: inline-flex; }
    .sa-checkbox-container .checkbox.disabled {
      opacity: 0.5;
      cursor: auto; }
    .sa-checkbox-container .checkbox.square {
      border-radius: 5px; }
      .sa-checkbox-container .checkbox.square.checked {
        background-color: var(--state-teal-default); }
        .sa-checkbox-container .checkbox.square.checked img {
          top: -1px;
          left: -1px;
          width: 15px;
          position: absolute; }

.sa-copy-clipboard {
  width: fit-content;
  position: relative; }
  .sa-copy-clipboard.button:hover .copy-tooltip, .sa-copy-clipboard.button:focus .copy-tooltip {
    opacity: 1; }
  .sa-copy-clipboard .copy-tooltip {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -170%);
    opacity: 0;
    padding: 2px 8px 2px 8px;
    background: #FFFFFF;
    margin-bottom: 4px;
    /* Shadow */
    box-shadow: 0px 2px 5px rgba(28, 26, 24, 0.1);
    border-radius: 4px;
    /* Text */
    font-family: Rubik;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #37414B;
    transition: 0.3s ease; }

.data-field {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto-Regular'; }
  .data-field.grow {
    flex-grow: 1; }
  .data-field_header {
    font-family: 'Rubik-Medium';
    padding: 5px 10px;
    border: 1px solid var(--neutral-grey-40);
    border-left: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #100E0D;
    background: var(--neutral-grey-20); }
    .data-field_headerLeft {
      display: flex;
      align-items: center; }
  .data-field_title {
    font-size: 14px; }
  .data-field_subtitle {
    margin-left: 5px;
    color: #00000099;
    font-size: 12px; }
  .data-field_body {
    padding: 10px;
    background: var(--neutral-white);
    flex-grow: 1;
    font-size: 13px;
    color: #1212127F;
    border-right: 1px solid var(--neutral-grey-40);
    border-top: none; }
    .data-field_body.error {
      color: var(--neutral-white);
      background: var(--state-red-default); }
  .data-field_select {
    min-width: 25px;
    display: flex;
    justify-content: space-between; }
    .data-field_select img {
      cursor: pointer; }
  .data-field_pages {
    color: var(--primary-blue-light);
    font-size: 12px;
    margin-left: 10px; }

.calendar-container {
  background: #fff;
  font-size: 5px;
  border: 1px solid #f4f5f6;
  padding: 16px; }

.calendar-grid {
  display: grid;
  grid-template: repeat(7, auto)/repeat(7, auto); }

.calendar-cell {
  padding: 0;
  margin: 0 auto;
  font-family: 'Rubik-Regular';
  text-align: center;
  align-self: center;
  width: 36px;
  height: 36px;
  font-size: 16px;
  line-height: 20px;
  border-radius: 100%;
  cursor: pointer;
  color: #ddd;
  background: none;
  transition: background 0.1s ease;
  border: none;
  user-select: none; }
  .calendar-cell:hover {
    color: #1c1a18;
    background: rgba(28, 26, 24, 0.1); }
  .calendar-cell:focus {
    border: 1px solid #3b83bf;
    box-shadow: 0 0 3px 1px #3b83bf;
    outline: none; }
  .calendar-cell:disabled {
    color: #ddd;
    background: none;
    cursor: not-allowed; }
  .calendar-cell.calendar-day {
    color: #787f88;
    margin: 0 0 8px 0;
    padding: 0 0 8px 0;
    width: 100%;
    height: auto;
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0;
    cursor: default; }
    .calendar-cell.calendar-day:hover {
      background: none; }
  .calendar-cell.is-in-month {
    color: #333; }
  .calendar-cell.is-today {
    border: 1px solid #3b83bf; }
  .calendar-cell.is-highlighted {
    color: #ffffff;
    background: #3b83bf; }

.calendar-header {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .calendar-header .arrow {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding: 1px;
    border: none;
    background-color: white;
    border-radius: 100%;
    user-select: none; }
    .calendar-header .arrow img {
      width: 25px;
      height: 25px; }
    .calendar-header .arrow.left:hover {
      background-color: #f4f5f6; }
    .calendar-header .arrow.right:hover {
      background-color: #f4f5f6; }
  .calendar-header .calendar-month {
    font-family: "Rubik-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px; }

.date-picker {
  max-width: 400px;
  font-family: 'Rubik-Regular';
  margin: 0 auto; }
  .date-picker .date-selected {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .date-picker .date-selected img {
      margin-right: 20px;
      width: 40px;
      height: 40px; }
  .date-picker .confirm-button {
    width: 100%;
    padding: 16px;
    font-size: 16px;
    line-height: 100%;
    background: #f4f5f6;
    font-weight: bold;
    border: none;
    border-top: 1px solid #E6E6E6;
    cursor: pointer;
    color: #912B88;
    font-family: 'Rubik-Regular'; }
    .date-picker .confirm-button[data-is-confirmed="true"] {
      color: #787f88; }

.sa-dot {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  display: inline-block;
  font-size: 12px; }
  .sa-dot.positive {
    background: var(--state-teal-default); }
  .sa-dot.negative {
    background: var(--state-red-default); }

.sa-dropdown {
  outline-color: var(--state-lightblue-default);
  position: relative;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  display: flex;
  border: 2px solid var(--neutral-grey-40);
  padding: 4px 0 4px 10px;
  color: var(--font-black);
  border-radius: 5px;
  width: auto;
  background: var(--neutral-white);
  font-size: 12px; }
  .sa-dropdown.error {
    border: 2px solid var(--state-red-default) !important; }
  .sa-dropdown-tags {
    display: flex;
    flex-wrap: wrap; }
    .sa-dropdown-tags .clear-button {
      min-height: unset; }
  .sa-dropdown.open {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px; }
    .sa-dropdown.open.curved {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px; }
      .sa-dropdown.open.curved .input input {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px; }
  .sa-dropdown.curved {
    border-radius: 20px; }
    .sa-dropdown.curved .input input {
      border-radius: 20px; }
  .sa-dropdown.contains-tags {
    padding: 4px 0 0 0 !important; }
    .sa-dropdown.contains-tags .tag {
      padding: 5px 15px 5px 10px;
      margin-left: 3px;
      margin-bottom: 4px; }
      .sa-dropdown.contains-tags .tag img {
        margin-left: 5px; }
    .sa-dropdown.contains-tags .sa-dropdown-icons {
      margin-bottom: 3px; }
  .sa-dropdown.disabled {
    opacity: 0.5;
    cursor: default; }
  .sa-dropdown-wrapper label {
    font-size: 12px;
    margin-bottom: 2px;
    color: var(--audio-player-wrapper);
    display: inline-block; }
  .sa-dropdown-icons {
    display: flex;
    justify-content: space-between;
    min-width: 24px;
    max-width: 50px;
    height: 24px;
    z-index: 3; }
    .sa-dropdown-icons * {
      height: 24px; }
    .sa-dropdown-icons .button {
      display: flex;
      align-items: center; }
    .sa-dropdown-icons-cross {
      margin: 0 8px;
      height: 8px; }
  .sa-dropdown-filter {
    position: absolute;
    display: flex;
    left: 0;
    top: -1px;
    height: 100%;
    width: 100%;
    z-index: 3; }
    .sa-dropdown-filter .input {
      border: none;
      border-radius: 5px;
      display: flex;
      margin: 0;
      height: 100%;
      width: 100% !important; }
      .sa-dropdown-filter .input input {
        width: 100%;
        padding-right: 30px;
        margin-top: 1px;
        height: 100%;
        padding-left: 30px;
        border-radius: 5px;
        border: none !important;
        outline-color: var(--state-lightblue-default); }
    .sa-dropdown-filter-icon {
      position: absolute;
      left: 8px;
      top: 8px;
      cursor: default; }
  .sa-dropdown-options {
    position: absolute;
    display: flex;
    left: -2px;
    z-index: 200;
    padding: 4px 2px;
    border-radius: 3px;
    box-sizing: border-box;
    flex-direction: column;
    border-bottom: 2px solid var(--neutral-grey-40);
    border-right: 2px solid var(--neutral-grey-40);
    border-left: 2px solid var(--neutral-grey-40);
    background: var(--neutral-white);
    max-height: 200px;
    overflow-y: scroll; }
    .sa-dropdown-options::-webkit-scrollbar {
      width: 7px; }
    .sa-dropdown-options::-webkit-scrollbar-track {
      display: none; }
    .sa-dropdown-options::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px #0000007F;
      border: 2px white solid; }
    .sa-dropdown-options-option {
      padding-left: 8px;
      min-height: 30px;
      max-height: 30px;
      border-radius: 3px;
      display: flex;
      align-items: center; }
      .sa-dropdown-options-option.no-results {
        cursor: default; }
      .sa-dropdown-options-option:hover, .sa-dropdown-options-option.expanded {
        font-weight: 500; }
      .sa-dropdown-options-option-label {
        width: 100%; }
      .sa-dropdown-options-option img {
        height: 10px; }
      .sa-dropdown-options-option .icon-button {
        width: auto;
        height: 30px; }
      .sa-dropdown-options-option .option-button {
        padding: 4px;
        display: flex;
        width: 100%;
        text-align: left;
        height: 30px;
        align-items: center; }
        .sa-dropdown-options-option .option-button:focus, .sa-dropdown-options-option .option-button:hover {
          font-weight: bold;
          outline: none;
          border-radius: 3px;
          background-color: var(--state-lightblue-default); }
          .sa-dropdown-options-option .option-button:focus .sa-dropdown-options-option-label, .sa-dropdown-options-option .option-button:hover .sa-dropdown-options-option-label {
            color: var(--neutral-white); }
      .sa-dropdown-options-option .add {
        right: 5px; }
        .sa-dropdown-options-option .add-icon {
          padding: 0;
          margin: 0;
          width: 22px; }
      .sa-dropdown-options-option .remove {
        right: 5px; }
        .sa-dropdown-options-option .remove-icon {
          padding: 0;
          margin: 0;
          transform: rotate(45deg);
          width: 22px; }
    .sa-dropdown-options.from-bottom {
      border-top: 2px solid var(--neutral-grey-40);
      border-bottom: none; }
  .sa-dropdown-placeholder {
    color: var(--primary-blue-light); }

.sa-box:not(.alt) .sa-dropdown-wrapper label {
  color: var(--neutral-white); }

.sa-alt-dropdown .sa-dropdown {
  padding: 4px 5px 4px 15px;
  background: #1C1A1819;
  border: none;
  font-size: 12px;
  border-radius: 15px; }

.input {
  font-family: 'Rubik-Regular', sans-serif;
  outline-color: lightgray;
  display: flex;
  flex-direction: column; }
  .input label {
    font-size: 12px;
    margin-bottom: 2px;
    color: var(--audio-player-wrapper); }
  .input textarea,
  .input input {
    width: 100%;
    outline-color: var(--state-lightblue-default);
    border-radius: 5px;
    border: 2px solid var(--outline-grey-40);
    padding: 8px 10px;
    font-size: 14px;
    background: var(--neutral-white);
    color: var(--font-black);
    font-family: var(--roboto-regular); }
    .input textarea:focus,
    .input input:focus {
      border: solid 2px var(--primary-blue-light); }
  .input .inner-input {
    width: 100%;
    position: relative; }
    .input .inner-input .password-visibility {
      position: absolute;
      left: calc(100% - 35px);
      bottom: 3px; }
  .input.disabled input {
    opacity: 0.5; }
  .input.error textarea,
  .input.error input {
    border: 2px solid var(--state-red-default) !important; }
  .input-error {
    font-size: 12px;
    color: var(--state-red-default);
    margin-top: 2px; }

.sa-box:not(.alt) .input label {
  color: var(--neutral-white); }

.sa-message {
  display: flex;
  margin: 10px 0; }
  .sa-message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 20px; }
  .sa-message.local {
    justify-content: flex-end; }
    .sa-message.local .sa-message-container {
      align-items: flex-end;
      margin-right: 0;
      margin-left: 20px; }
  .sa-message.remote {
    justify-content: flex-start; }
  .sa-message-inner {
    padding: 10px 20px;
    color: #000;
    border-radius: 10px;
    background: var(--neutral-white);
    display: inline-block;
    clear: both; }
    .sa-message-inner.local {
      background: var(--primary-blue-light);
      border-bottom-right-radius: 2px; }
    .sa-message-inner.remote {
      border-bottom-left-radius: 2px; }
  .sa-message-time {
    color: var(--font-black);
    font-size: 11px;
    margin-top: 2px; }

.zendesk-messages {
  overflow: hidden; }
  .zendesk-messages .time {
    display: block;
    margin-bottom: 6px;
    font-size: smaller; }

.ms input[type=checkbox] {
  width: 0;
  height: 0;
  opacity: 0;
  margin: 0; }

.ms-wrapper {
  display: flex;
  align-items: flex-end;
  width: fit-content; }
  .ms-wrapper.label-right {
    flex-direction: row-reverse; }
  .ms-wrapper .checkbox-label {
    cursor: pointer; }
  .ms-wrapper .checkbox {
    cursor: pointer;
    width: 22px;
    height: 22px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 1px solid var(--neutral-grey-60);
    background-color: var(--neutral-white); }
    .ms-wrapper .checkbox .no-drag {
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none; }
    .ms-wrapper .checkbox-wrapper {
      display: inline-flex; }
    .ms-wrapper .checkbox-label {
      margin-right: 5px; }
    .ms-wrapper .checkbox.focused {
      outline: auto;
      outline-color: darkgray; }
    .ms-wrapper .checkbox.state-blank {
      background-color: var(--neutral-white);
      border: 1px solid var(--primary-black-dark); }
    .ms-wrapper .checkbox.disabled {
      opacity: 0.5;
      cursor: auto; }
    .ms-wrapper .checkbox.square {
      border-radius: 3px;
      border: 1px solid var(--neutral-grey-60); }
    .ms-wrapper .checkbox .cross {
      margin: 3px; }
    .ms-wrapper .checkbox .horizontal-line {
      width: 12px;
      height: 2px;
      background-color: var(--neutral-grey-20);
      top: 50%;
      margin-top: -1px;
      left: 50%;
      margin-left: -6px;
      position: relative; }

.popup-container {
  position: fixed;
  z-index: 255256;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #0000004C; }
  .popup-container.no-shadow {
    background-color: initial;
    z-index: 255257;
    height: 0; }
  .popup-container.no-overflow .popup {
    overflow-y: unset; }
  .popup-container.move .popup {
    cursor: move; }
  .popup-container.center .popup {
    width: max-content;
    min-width: 50vh;
    height: max-content;
    max-height: 90vh;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto; }
  .popup-container .popup {
    display: inline-block;
    padding: 20px 30px 20px 30px;
    background-color: var(--neutral-white);
    border-radius: 10px;
    box-shadow: 0 0 20px 0 #00000019;
    max-height: 80vh;
    position: fixed;
    z-index: 100;
    overflow-y: auto;
    outline: none; }
    .popup-container .popup.center {
      left: 0;
      right: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
    .popup-container .popup.small {
      padding: 0; }
    .popup-container .popup-inner .child-click {
      width: 100%;
      height: 100%;
      cursor: default;
      font-family: inherit;
      font-size: inherit;
      text-align: inherit; }
    .popup-container .popup-close {
      position: absolute;
      right: 10px;
      top: 10px; }
  .popup-container.centered-popup .popup {
    margin-top: 10px;
    overflow-y: visible; }
    .popup-container.centered-popup .popup:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      left: calc(50% - 12px);
      top: -10px;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid var(--neutral-white);
      border-radius: 3px; }

.sa-radio-container {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  margin-right: 10px; }
  .sa-radio-container .sa-radio {
    position: relative;
    height: 18px;
    width: 18px;
    border: 1px solid lightgrey;
    border-radius: 100%;
    margin-right: 10px;
    transition: all 0.2s ease; }
    .sa-radio-container .sa-radio.checked {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease; }
      .sa-radio-container .sa-radio.checked:after {
        content: "";
        background-color: var(--state-teal-default);
        width: 10px;
        height: 10px;
        border-radius: 100%; }
    .sa-radio-container .sa-radio.focused {
      outline: auto;
      outline-color: var(--state-lightblue-default);
      background-color: #ccc; }
    .sa-radio-container .sa-radio:hover {
      background-color: #ccc; }
    .sa-radio-container .sa-radio.disabled {
      opacity: 0.5;
      cursor: auto; }
  .sa-radio-container input[type=radio] {
    height: 0;
    width: 0;
    opacity: 0;
    margin: 0; }

.sa-slideout {
  position: fixed;
  display: flex !important;
  flex-direction: column;
  top: 0; }
  .sa-slideout-wrapper {
    position: fixed;
    z-index: 255255; }
  .sa-slideout .close-button {
    outline-color: darkgray; }
    .sa-slideout .close-button .close {
      text-decoration: underline; }
  .sa-slideout-overlay {
    position: fixed;
    z-index: 255254;
    width: 100vw;
    height: 100vh;
    opacity: 0.5;
    top: 0;
    left: 0;
    background-color: var(--primary-black-dark); }

.main {
  font-family: 'Rubik-Regular';
  width: 300px;
  height: fit-content;
  background-color: #F4F5F6;
  border-radius: 8px;
  margin: 0 auto; }
  .main .summary-confirmed {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .main .summary-confirmed img {
      margin-right: 20px;
      width: 40px;
      height: 40px; }
  .main .summary-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-bottom: 1px solid #E6E6E6; }
  .main h1 {
    font-family: 'Rubik';
    margin: 0;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; }
  .main .fields-container {
    font-size: 14px;
    padding: 0 10px;
    background-color: white;
    border-radius: 4px; }
    .main .fields-container .customer-data {
      border-radius: 4px;
      background-color: white;
      padding-bottom: 10px;
      display: flex;
      padding: 10px 0; }
      .main .fields-container .customer-data:not(:last-child) {
        border-bottom: 1px solid #E6E6E6; }
      .main .fields-container .customer-data .data-block {
        font-size: 12px;
        display: flex;
        align-items: flex-start;
        width: 100%; }
        .main .fields-container .customer-data .data-block .field-label {
          margin-top: 3px;
          overflow: hidden;
          font-family: 'Rubik';
          white-space: nowrap;
          font-weight: 500;
          margin-right: 5px;
          width: 70%;
          max-width: 70%; }
          .main .fields-container .customer-data .data-block .field-label.editable {
            width: fit-content;
            overflow: unset; }
        .main .fields-container .customer-data .data-block .data {
          margin: auto 0;
          font-weight: 400;
          font-family: 'Rubik';
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 18px; }
          .main .fields-container .customer-data .data-block .data input {
            font-size: 12px;
            font-weight: 400;
            font-family: 'Rubik';
            border: 1px solid #3B83BF;
            box-sizing: border-box;
            width: 100%;
            padding: 4px;
            border-radius: 5px; }
          .main .fields-container .customer-data .data-block .data .address {
            font-weight: 400; }
            .main .fields-container .customer-data .data-block .data .address span {
              display: block; }
        .main .fields-container .customer-data .data-block button {
          font-size: 12px;
          font-weight: 400;
          font-family: 'Rubik';
          margin-left: 2px;
          cursor: pointer;
          padding: 5px;
          border: none;
          background-color: white;
          color: #2D9BF0;
          text-decoration: underline; }
  .main .button-container {
    width: 100%;
    border-radius: 10px;
    box-sizing: border-box; }
    .main .button-container button {
      padding: 16px;
      font-weight: 500;
      font-family: 'Rubik';
      cursor: pointer;
      width: 100%;
      font-size: 16px;
      color: #0C3274;
      border: none;
      background: none; }

.sa-table {
  width: 100%;
  color: var(--primary-black-dark);
  border-collapse: collapse; }
  .sa-table.sa-fixed-cols {
    table-layout: fixed; }
  .sa-table .sa-has-sort {
    cursor: pointer; }
  .sa-table .sa-row-select {
    cursor: pointer; }
    .sa-table .sa-row-select.sa-row-selected {
      outline: -webkit-focus-ring-color auto 5px;
      outline-color: var(--neutral-grey-40);
      background: rgba(28, 26, 24, 0.1);
      box-shadow: none !important; }
    .sa-table .sa-row-select:hover {
      box-shadow: 0 2px 4px 0 #00000047; }
  .sa-table td, .sa-table th {
    padding: 12px 10px;
    border-bottom: 1px solid var(--outline-grey-40);
    text-align: left; }
    .sa-table td:first-child, .sa-table th:first-child {
      padding-left: 20px; }
  .sa-table th {
    color: var(--primary-black-dark);
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-family: 'Roboto-Medium';
    padding-right: 10px; }
  .sa-table td {
    font-family: 'Roboto-Regular'; }
    .sa-table td.sa-row-title {
      color: var(--primary-black-dark); }
  .sa-table .sa-sort-arrow {
    position: absolute;
    margin-left: 5px; }
  .sa-table .sa-sorted-by {
    user-select: none; }
  .sa-table .sa-col-center {
    text-align: center; }
  .sa-table .sa-col-right {
    text-align: right; }
  .sa-table tfoot td {
    border-bottom: none; }
  .sa-table.no-data {
    padding: 20px 0;
    text-align: center; }
  .sa-table .sa-no-table-data {
    color: var(--primary-black-dark);
    font-size: 12px; }
  .sa-table .accessibility-hidden {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute; }

.sa-pagination {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  align-items: center;
  color: var(--primary-blue-light);
  margin: 8px;
  height: 24px; }
  .sa-pagination-relative {
    display: flex;
    flex-direction: row;
    height: 24px;
    align-items: center; }
    .sa-pagination-relative > div {
      font-family: 'Rubik-Medium';
      font-size: 12px;
      color: var(--outline-grey-100);
      height: 24px;
      max-height: 24px;
      text-align: center;
      line-height: 22px;
      padding-left: 8px;
      padding-right: 8px; }
    .sa-pagination-relative button {
      width: 24px; }
    .sa-pagination-relative button:first-of-type {
      border-radius: 4px 0 0 4px; }
    .sa-pagination-relative button:last-of-type {
      border-radius: 0 4px 4px 0; }
  .sa-pagination-relative > * {
    height: 24px;
    max-height: 24px;
    background: white;
    border-radius: 0;
    border: 1px solid var(--neutral-grey-40);
    border-right-width: 0; }
  .sa-pagination-relative > *:last-child {
    border-right-width: 1px; }
  .sa-pagination-relative > *:hover {
    cursor: pointer; }
  .sa-pagination-absolute {
    display: flex;
    height: 24px; }
    .sa-pagination-absolute span {
      font-family: 'Rubik-Regular';
      font-size: 14px;
      margin-left: 15px;
      line-height: 24px; }
    .sa-pagination-absolute-selector {
      font-family: 'Rubik-Medium';
      font-size: 12px;
      margin-left: 15px;
      line-height: 24px;
      display: initial;
      align-items: center;
      min-width: 24px;
      border-radius: 4px;
      background: white;
      border: 1px solid var(--neutral-grey-40);
      text-align: center;
      margin-left: 4px;
      margin-right: 4px; }
      .sa-pagination-absolute-selector div input {
        line-height: 24px;
        padding: 0;
        border: 0; }
    .sa-pagination-absolute-go {
      background: var(--state-lightblue-default) !important;
      color: white !important;
      padding-left: 6px !important;
      padding-right: 6px !important;
      border-radius: 4px !important;
      border-width: 0px !important; }

.hover-over {
  height: 100px;
  background-color: var(--neutral-grey-40);
  display: flex;
  align-items: center;
  margin-top: 50px; }

.tooltip-parent {
  position: inherit; }

.tooltipContainer {
  position: fixed;
  pointer-events: none;
  z-index: 1000; }
  .tooltipContainer .tooltip {
    width: 150px;
    background-color: var(--state-lightblue-default);
    color: #fff;
    border-radius: 3px;
    padding: 8px;
    box-shadow: 0px 2px 10px 0px #00000019; }
    .tooltipContainer .tooltip .tooltip-inner {
      text-transform: none;
      text-align: center;
      font-family: 'Roboto-Regular';
      font-size: 12px; }
    .tooltipContainer .tooltip:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 8px solid var(--state-lightblue-default);
      position: absolute;
      right: 75px;
      bottom: -7px; }

.sa-tabs .sa-tab-headers {
  border-bottom: 1px solid var(--neutral-grey-40);
  display: flex; }
  .sa-tabs .sa-tab-headers .sa-tab {
    cursor: pointer;
    padding: 10px;
    font-size: 15px;
    color: var(--neutral-grey-40); }
    .sa-tabs .sa-tab-headers .sa-tab.selected {
      color: var(--font-black);
      box-shadow: inset 0 -3px 0 0 #43b392; }

.sa-tabs .sa-tab-content {
  margin-top: 20px; }

.tag {
  border-radius: 20px;
  padding: 10px 20px;
  color: var(--neutral-white);
  display: inline-flex;
  justify-content: space-between;
  font-family: "Rubik-Medium", sans-serif;
  font-size: 12px; }
  .tag.square {
    border-radius: 3px; }
  .tag.white {
    background: var(--neutral-white);
    color: var(--primary-black-default); }
  .tag.blue {
    background: var(--state-lightblue-default); }
  .tag.green {
    background: var(--state-teal-default); }
  .tag.red {
    background: var(--state-red-default); }
  .tag.orange {
    background: var(--state-orange-default); }
  .tag.dark {
    background: var(--neutral-grey-80);
    color: #fff; }
  .tag.grey {
    background: var(--neutral-grey-20);
    color: var(--primary-black-default); }
  .tag.small {
    padding: 5px 15px; }
  .tag.red-reverse {
    background-color: var(--state-red-light);
    color: var(--neutral-white); }
  .tag.sa-blue {
    background: var(--primary-blue-default); }
  .tag.inherit {
    background: inherit; }

.sa-toggle-switch {
  width: 36px;
  height: 20px;
  border-radius: 15px;
  cursor: pointer;
  position: relative; }
  .sa-toggle-switch.on {
    background: #43b392; }
  .sa-toggle-switch.off {
    background: var(--state-red-default); }
  .sa-toggle-switch-circle {
    border-radius: 15px;
    position: absolute;
    height: 14px;
    width: 14px;
    top: 3px;
    transition: right .3s; }
    .sa-toggle-switch-circle.on {
      background: var(--neutral-white);
      right: 3px; }
    .sa-toggle-switch-circle.off {
      background: var(--neutral-white);
      right: 19px; }
  .sa-toggle-switch-wrapper {
    display: flex;
    align-items: center; }
  .sa-toggle-switch-label {
    padding-left: 9px;
    font-weight: bold;
    font-size: 12px; }

.sa-typing-indic {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%; }
  .sa-typing-indic-wrapper {
    background: #fff;
    width: 75px;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    height: 35px; }
  .sa-typing-indic-dot {
    -webkit-animation: typingAnimation 1.5s infinite ease-in-out;
    background-color: #90949c;
    border-radius: 10px;
    display: inline-block;
    height: 12px;
    margin-right: 2px;
    width: 12px; }
    .sa-typing-indic-dot:nth-child(1) {
      -webkit-animation-delay: 200ms; }
    .sa-typing-indic-dot:nth-child(2) {
      -webkit-animation-delay: 300ms; }
    .sa-typing-indic-dot:nth-child(3) {
      -webkit-animation-delay: 400ms; }

@-webkit-keyframes typingAnimation {
  0% {
    -webkit-transform: translateY(0px); }
  28% {
    -webkit-transform: translateY(-5px); }
  44% {
    -webkit-transform: translateY(0px); } }

:root {
  font-family: 'Rubik-Medium';
  --rubik-light: 'Rubik-Light';
  --rubik-medium: 'Rubik-Medium';
  --rubik-regular: 'Rubik-Regular';
  --roboto-regular: 'Roboto-Regular';
  --roboto-medium: 'Roboto-Medium';
  --font-black: #000000;
  --opacity-white-05: #FAFAFA0C;
  --opacity-white-10: #FAFAFA19;
  --opacity-white-30: #FAFAFA4C;
  --opacity-blue-15: #55627226;
  --opacity-black-10: #00000019;
  --opacity-black-11: #0000001C;
  --opacity-black-15: #00000026;
  --opacity-black-30: #1212124C;
  --box-header: #1c1a1840;
  --box-body: #1c1a1819;
  --checkbox-border: #d5d5d5;
  --main-button: #e9ebed;
  --menu-nav-active: #43b392;
  --agents-background: #394d65;
  --search-input-input: #EAECEE;
  --contact-tag: #4B5C6F;
  --joint-button: #3b5169;
  --plugin-tag-background: #efe9e9;
  --stats-border-grey: #DADADA;
  --stats-hover-grey: #CDCCCD;
  --switcher-grey: #CCCCCC;
  --login-left-color: #333333;
  --login-error: #ff6161;
  --main-link-hover: #1E3246;
  --nav-voice-icon: #8C97A1;
  --menu-aftercall-background: #101010;
  --realtime-dropdown-font: #464646;
  --dialler-keypad-label: #696969;
  --delete-dialog-font: #959595;
  --user-form-error: #f96854;
  --audio-player-wrapper: #787f88;
  --track-background-plank: #dfdfdf;
  --track-2-background: #3f84bd;
  --calllog-background: #293644;
  --primary-blue-light:   #9FABB8;
  --primary-blue-default: #405772;
  --primary-blue-dark:    #2B3D51;
  --primary-black-light:  #8D8C8B;
  --primary-black-default:#1C1A18;
  --primary-black-dark:   #100E0D;
  --primary-black:        #000000;
  --state-red-light:   #FF9B8D;
  --state-red-default: #FF381B;
  --state-red-dark:    #D23219;
  --state-orange-light:   #EEB97F;
  --state-orange-default: #DE7300;
  --state-orange-dark:    #B45A00;
  --state-teal-light:   #7FCFC1;
  --state-teal-default: #009F84;
  --state-teal-dark:    #007D69;
  --state-lightblue-light:  #9DC1DF;
  --state-lightblue-default:#3B83BF;
  --state-lightblue-dark:   #1D6098;
  --brand-blue:  #007CFF;
  --brand-green: #43CB53;
  --neutral-grey-100:#2D323C;
  --neutral-grey-80: #69696E;
  --neutral-grey-60: #A0A0A0;
  --neutral-grey-40: #E6E6E6;
  --neutral-grey-20: #F4F5F6;
  --neutral-white:   #FFFFFF;
  --shadow-over:  10px 2px 10px #000000;
  --shadow-out:   10px 2px 5px  #000000;
  --outline-grey-100:#37414B;
  --outline-grey-80: #556272;
  --outline-grey-60: #959DA7;
  --outline-grey-40: #E1E1E6;
  --outline-grey-20: #F4F5F6;
  --outline-white:   #FFFFFF;
  --outline-red:       #FF381B;
  --outline-orange:    #DE7300;
  --outline-teal:      #009F84;
  --outline-lightblue: #3B83BF;
  --outline-blue:      #405772;
  --underline-red:       #FF381B;
  --underline-orange:    #DE7300;
  --underline-teal:      #009F84;
  --underline-lightblue: #3B83BF;
  --underline-blue:      #405772;
  --misc-tint-light:     #FFFFFF;
  --misc-tint-dark-10:   #1C1A181A;
  --misc-tint-dark-60:   #1C1A1899;
  --misc-tint-red:       #FF381B19;
  --misc-tint-orange:    #DE730019;
  --misc-tint-teal:      #009F8419;
  --misc-tint-lightblue: #3B83BF19;
  --misc-tint-blue:      #40577219;
  --demo-yellow: #F9C754;
  --demo-purple: #9D82AB;
  --demo-green:  #7FA256;
  --demo-blue:   #5667A2;
  --demo-violet: #A2568A; }
